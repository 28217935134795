<template>
  <div>
    <LayoutModelRead
      :key="_id"
      v-bind="this.$store.state.stone_model[_modelName]"
      :urlModelName="_urlModelName"
      :label="_label"
    ></LayoutModelRead>
  </div>
</template>

<script>
export default {
  computed: {
    _modelName() {
      return this.$route.meta.modelName;
    },
    _label() {
      let _label = "";
      if (this.$route.meta && this.$route.meta.label) {
        _label = this.$route.meta.label;
      } else if (this.$store.state.stone_model[this._modelName].label) {
        _label = this.$store.state.stone_model[this._modelName].label;
      }
      return _label;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
    _id() {
      return this.$route.params.id  
    },
  },
  mounted() {},
};
</script>